import * as React from 'react';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

export interface IQuantityInputProps {
    product: SimpleProduct | undefined;
    quantity: number;
    breakoff: boolean;
    showOnlyIncrementDecrement?: boolean;
    showNonBreakoffAmount: boolean;
    onQuantityChange: (quantity: number) => void;
    BuyButton?: React.ReactNode;
}

const QuantityInput = (props: IQuantityInputProps) => {
    const {
        product,
        quantity,
        breakoff,
        showOnlyIncrementDecrement,
        showNonBreakoffAmount,
        onQuantityChange
    } = props;

    const baseClassName = 'quantity-input';
    // Check if there is a friendly UOM defined on the SimpleProduct
    const friendlyUOM = product?.ExtensionProperties?.find((x) => x.Key === 'FRIENDLYUOM')?.Value?.StringValue;
    const uom = friendlyUOM || product?.DefaultUnitOfMeasure;
    // const selectedUOM = breakoff ? uom : 'KASSE(R)';
    const multiplier = breakoff ? product?.Behavior?.MultipleOfQuantity || 1 : product?.Behavior?.DefaultQuantity || 1;

    const onIncrement = () => {
        const multiplier = breakoff ? product?.Behavior?.MultipleOfQuantity || 1 : product?.Behavior?.DefaultQuantity || 1;
        const newQuantity = quantity + multiplier;
        onQuantityChange(newQuantity);
    };

    const onDecrement = () => {
        const multiplier = breakoff ? product?.Behavior?.MultipleOfQuantity || 1 : product?.Behavior?.DefaultQuantity || 1;
        const newQuantity = Math.max(0, quantity - multiplier);
        onQuantityChange(newQuantity);
    };

    const displayQuantity = parseFloat((quantity / multiplier).toFixed(2));

    return (
        <div className={baseClassName}>
            <div className={`${baseClassName}__quantity-container`}>
                <div className={`${baseClassName}__quantity-controls`}>
                    <button
                        className={`${baseClassName}__quantity-controls__minus`}
                        disabled={!product}
                        onClick={onDecrement}
                    >
                        -
                    </button>
                    <button
                        className={`${baseClassName}__quantity-controls__plus`}
                        disabled={!product}
                        onClick={onIncrement}
                    >
                        +
                    </button>
                    {!showOnlyIncrementDecrement && product && (
                        <span className={`${baseClassName}__quantity-controls__quantity`}>
                            {showNonBreakoffAmount && (!breakoff && multiplier > 1)
                                ? `${displayQuantity} X ${multiplier} ${uom}`
                                : `${displayQuantity} ${uom}` }
                        </span>
                    )}
                </div>

                <div className={`${baseClassName}__buy-container`}>
                    {props.BuyButton}
                </div>
            </div>
        </div>
    );
};

export default QuantityInput;
